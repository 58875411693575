<template>
  <agency-login v-if="isMaccabiAgency"/>
  <div v-else>
    <Banner :imageList="bannerImages" type="home" />
    <h1 class="flying-advertisement text-center mt-3 mb-0" v-if="!forPricePlanOnly">{{ $t("home.flying-advertisement")}}</h1>
    <TopBanner :topBanner="topBanner" v-if="topBanner.length > 0 && !forPricePlanOnly"/>

    <div v-if="!promotionList.length && !dealData && !bypassPaymentState && !forPricePlanOnly" class="loading-message">{{ $t('home.deal-loading-message') }}</div>

    <Lesiurpackages :promotionData="promotionList" v-if="!!promotionList.length && !forPricePlanOnly" />

    <Lesiurtabs
      :hasDealType="hasDealTypeList"
      :banner="middleBanner"
      v-if="!!hasDealTypeList && !!middleBanner && !forPricePlanOnly && !isMaccabiAgency"
      ref="dealTab"
      :showBanner="showMiddleBanner"
      :bannerHref="middleBannerHref"
    />
    <ContentLoading v-else-if="!forPricePlanOnly && !isMaccabiAgency" type="deal" />
    <Footer v-if="!hideCondition"/>

  </div>
</template>

<script>
import TemplateHome from './templateHome';

export default {
  name: 'HomeTheme0',
  mixins: [TemplateHome],
  components: {
    Banner: () => import('@/components/content/banner/BannerTheme0'),
    Lesiurpackages: () => import('@/components/home/Lesiurpackages'),
    Lesiurtabs: () => import('@/components/home/lesiurtabs/LesiurtabsTheme0'),
    Footer: () => import('@/components/HeaderFooter/footer/FooterTheme0'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    TopBanner: () => import('@/components/home/topBanner/TopBannerTheme0'),
    AgencyLogin: () => import('@/components/agencyLogin/login'),
  },
};
</script>

<style scoped>
  .flying-advertisement {
    font-family: "ploni";
    font-weight: bold;
    font-size: 3rem;
    text-transform: capitalize;
  }

@media (max-width: 767px) {
  .flying-advertisement {
    font-size: 2rem;
    padding: 5px;
  }
}
</style>
