import { mapGetters } from 'vuex';
import getBannerImage from '@/utils/getBannerImage';

export default {
  name: 'TemplateHome',
  mixins: [getBannerImage],
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters({
      dealTypes: 'GET_DEAL_TYPE',
      dealData: 'GET_DEAL_DATA',
      hasDealType: 'GET_HAS_DEAL_TYPE',
      marketerId: 'GET_MARKETER_ID',
      marketerAgency: 'GET_MARKETER_AGENCY',
      currentPage: 'GET_CURRENT_PAGE',
      bypassPaymentState: 'GET_BYPASS_PAYMENT_STATE',
      limitDealsState: 'GET_LIMIT_DEALS_STATE',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      forPricePlanOnly: 'GET_FOR_PRICE_PLAN_ONLY',
      isMaccabiAgency: 'GET_IS_MACCABI_AGENCY',
    }),
    hideCondition() {
      const { bypassPaymentState, limitDealsState, query } = this;
      return ((bypassPaymentState || limitDealsState) && query.secret !== null && query.secret !== process.env.VUE_APP_TEMPORARY_SECRET_PARM);
    },
    promotionList() {
      const { dealData } = this;
      if (!dealData) return [];

      const result = dealData.filter((item) => item.isPromotion);

      if (result.length === 1) {
        result.push(...result);
        result.push(...result);
      } else if (result.length === 2) {
        result(...result);
      } else if (result.length === 3) {
        result.push(result[0]);
      }
      return result;
    },
    hasDealTypeList() {
      const { dealTypes, hasDealType } = this;
      if (!dealTypes || !hasDealType) return null;
      return dealTypes.data.filter((type) => (
        hasDealType.includes(type.code) && (type.forHomeOrLanding === 'both' || type.forHomeOrLanding === 'homepage')
      ));
    },
  },
  data() {
    return {
      beforePage: '',
      query: this.$route.query,
    };
  },
  async created() {
    this.beforePage = this.currentPage;
    this.$store.commit('SET_CURRENT_PAGE', 'home');
    window.sessionStorage.setItem('marketerURL', '/');
    window.sessionStorage.removeItem('roomList');

    if (!this.dealTypes) this.$store.dispatch('FETCH_DEAL_TYPE_LIST');
    if (!this.hasDealType) this.$store.dispatch('FETCH_HAS_DEAL_TYPE_LIST');
    this.$store.dispatch('FETCH_IMAGES_EVENT');

    this.$store.dispatch('FETCH_SUBJECT_CATEGORY');
    this.$store.dispatch('FETCH_SUB_CATEGORIES_WITH_DEST');
    this.$store.dispatch('FETCH_SUBJECTS');
    const { dealType, marketerId } = this.query;
    if (!this.marketerId && !this.marketerAgency && !dealType && !marketerId) {
      this.$store.dispatch('FETCH_DEAL_ITINERARY');
    }
  },
  mounted() {
    window.sessionStorage.removeItem('landing-url');
    // setTimeout(() => { this.goDealTab(); }, 0);
    // if (this.isOdysseySite) this.$store.commit('SET_SELECTED_ODYSSEY_AGENT_CODE', this.agentContent.odyAgentCode);
  },
  methods: {
    goDealTab() {
      const offset = this.$refs.dealTab?.$children[0]?.$el.offsetTop || 1000;
      if (offset && this.beforePage === 'product') { document.querySelector('.st-content').scrollTo(0, offset); }
    },
  },
};
